import { Button, Text, useCanImageLoad } from '@hawkins/components';
import React, { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@hawkins/styles';
import { Controller, MediaPlayer, MediaPlayerVariant } from '@hawkins-community/media-player';
import { ProgressBar } from '@hawkins-community/media-player/components/ProgressBar';
import { IconDownload, IconExpand } from '@hawkins/icons';
import { colors, sizingValues, spacings } from '@hawkins/variables';
import { AssetBrowserItem, AssetBrowserItemProps } from '@hawkins-community/asset-browser/v4';
import { PlaybackController } from '@hawkins-community/media-player/components/PlaybackController';

import { useAssetDetail } from '../batch/useDetailCache';
import { proxyUrl, thumbnailUrl } from '../../util';
import { CounterChip } from '../CounterChip';
import { BatchContext, PasswordContext } from '../../context';
import { ErrorChildren, ThumbnailError } from '../Thumbnail';

interface ShareItemProps {
  itemId: string;
  index: number;
  total: number;
}
const useItemStyles = makeStyles({
  item: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr fit-content(100%)',
  },
  actionContainer: {
    gridColumnStart: 6,
    gridColumnEnd: 7,
    gridRowStart: 2,
    gridRowEnd: 7,
    alignSelf: 'start',
    justifySelf: 'end',
    display: 'flex',
    flexDirection: 'column',
    gap: spacings.space1,
    zIndex: 10,
  },
  indexChip: {
    gridColumnStart: 2,
    gridColumnEnd: 5,
    gridRowStart: 2,
    gridRowEnd: 5,
    alignSelf: 'start',
    justifySelf: 'start',
  },
  button: {
    borderRadius: '50%',
    backgroundColor: colors.blackT40,
    width: sizingValues.size3,
    height: sizingValues.size3,

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      backgroundColor: colors.grayT70,
    },
  },
});
const useGridMediaPlayerStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  controls: {
    position: 'absolute',
    width: '100%',
    bottom: '25%',
    opacity: '0.5',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      opacity: 1,
    },
  },
});

const GridMediaPlayer = (props: { itemId: string }) => {
  const { itemId } = props;
  const { shareKey } = useParams();
  const [controller, setController] = useState<Controller | null>(null);
  const classes = useGridMediaPlayerStyles();
  const password = useContext(PasswordContext);
  const { data: assetDetails } = useAssetDetail(shareKey || null, itemId, password);
  // const mediaPlayerConfig = useMemo(
  //   () => ({
  //     customControls: { left: [], right: [], center: [new PlayPauseControl(1)] },
  //   }),
  //   [],
  // );
  const onClick = useCallback((evt: SyntheticEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
  }, []);
  if (assetDetails && assetDetails.isVideoPlayable && shareKey) {
    return (
      <div className={classes.container}>
        <MediaPlayer
          variant={MediaPlayerVariant.NoControls}
          onInit={setController}
          // config={mediaPlayerConfig}
          autoPlay
          viewable={{
            video: {
              url: proxyUrl(shareKey, itemId.toString(), password),
              frameRateTag: 'FPS_2397',
            },
          }}
          watermark={{
            hide: true,
          }}
        />
        {controller ? (
          <div className={classes.controls} onClick={onClick}>
            <ProgressBar controller={controller as PlaybackController} />
          </div>
        ) : null}
      </div>
    );
  }
  return null;
};
export const GridItem = (props: ShareItemProps): JSX.Element | null => {
  const { itemId, index, total } = props;
  const { shareKey } = useParams();
  const password = useContext(PasswordContext);
  const batch = useContext(BatchContext);

  const [objectFit, setObjectFit] = useState<AssetBrowserItemProps['objectFit']>('cover');
  const calculateFit = useCallback<NonNullable<Required<AssetBrowserItemProps>['onLoad']>>((e) => {
    const image = e.target as HTMLImageElement;
    const objectFit = image.naturalWidth > image.naturalHeight ? 'cover' : 'contain';
    setObjectFit(objectFit);
  }, []);

  const classes = useItemStyles();
  const { data: assetDetails } = useAssetDetail(shareKey || null, itemId, password);
  const status = useCanImageLoad(thumbnailUrl(shareKey || '', itemId, password));
  const playerItem = useMemo(
    () =>
      status === 'loaded' && assetDetails?.isVideoPlayable === true ? <GridMediaPlayer itemId={itemId} /> : undefined,
    [assetDetails, itemId, status],
  );

  const canDownload = useMemo(
    () =>
      batch?.share.isDownloadable === true &&
      (!assetDetails?.isVideoPlayable ||
        (assetDetails?.isVideoPlayable && assetDetails?.watermarkStatus !== 'IN_PROGRESS')),
    [assetDetails, batch?.share.isDownloadable],
  );

  const source = useMemo(() => {
    if (status === 'loading' || !shareKey) {
      return undefined;
    }

    if (status === 'error' || assetDetails?.watermarkStatus === 'IN_PROGRESS') {
      return (
        <ThumbnailError>
          <ErrorChildren assetDetails={assetDetails} />
        </ThumbnailError>
      );
    }

    return thumbnailUrl(shareKey, itemId, password);
  }, [assetDetails, itemId, password, shareKey, status]);

  if (!itemId || !shareKey) {
    return null;
  }

  return (
    <div className={classes.item}>
      <AssetBrowserItem
        itemKey={itemId}
        objectFit={status === 'loaded' ? objectFit : 'contain'}
        // hoverSrc={playerItem}
        highlightSrc={playerItem}
        src={source}
        alt={assetDetails?.name || ''}
        onLoad={calculateFit}
      >
        <div
          className={classes.actionContainer}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
        >
          <Button
            className={classes.button}
            variant='icon'
            compact
            disabled={!canDownload}
            icon={IconDownload}
            component={Link}
            to={`/${shareKey}/${itemId}/download`}
          >
            {canDownload ? 'Download' : 'Downloads are disabled for this share'}
          </Button>
          <Button
            className={classes.button}
            compact
            variant='icon'
            icon={IconExpand}
            component={Link}
            to={`/${shareKey}/${itemId}`}
          >
            View Details
          </Button>
        </div>
        <CounterChip index={index} total={total} />
      </AssetBrowserItem>
      <Text variant='label' bold level={1}>
        {prepFileNameForWrap(assetDetails?.name || '')}
      </Text>
    </div>
  );
};
export function prepFileNameForWrap(title: string) {
  return (
    <div
      style={{
        wordWrap: 'break-word',
        wordBreak: 'break-all',
      }}
    >
      {title}
    </div>
  );
}
